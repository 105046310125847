@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #0a0a0a;
  margin: 0 auto;
  
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1F1F1F;
}

#root {
  @apply w-full md:max-w-[400px] my-0 mx-auto bg-gray-100 bg-opacity-60 h-full flex flex-1 items-start
}

.search-control {
  @apply bg-white text-sm appearance-none border-2 border-gray-200 rounded-xl w-full py-2 pr-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 focus:ring-2
}
.form-control {
  @apply bg-white text-sm appearance-none border-2 border-gray-200 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 focus:ring-2
}

.btn {
  @apply min-w-[140px] font-semibold text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-4 focus:ring-sky-300  rounded-full text-sm px-5 py-2.5 text-center  dark:bg-sky-600 dark:hover:bg-sky-500 dark:focus:ring-sky-300
}
.btn-pills {
  @apply min-w-[140px] font-semibold text-blue-500 bg-white border-2 border-blue-400 focus:outline-none focus:ring-4 focus:ring-blue-300  rounded-full text-sm px-5 py-2.5 text-center  dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-300
}
.btn-orange-pills {
  @apply min-w-[140px] font-semibold text-orange-500 bg-white border-2 border-orange-400 focus:outline-none focus:ring-4 focus:ring-orange-300  rounded-full text-sm px-5 py-2.5 text-center  dark:bg-orange-600 dark:hover:bg-orange-500 dark:focus:ring-orange-300
}
.btn-orange {
  @apply min-w-[140px] font-semibold text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300  rounded-full text-sm px-5 py-2.5 text-center  dark:bg-orange-600 dark:hover:bg-orange-500 dark:focus:ring-orange-300
}
.btn-white {
  @apply  font-semibold border rounded-lg text-sm px-5 py-2.5 text-center 
}

.btn-sm {
  @apply px-3 py-1 text-sm
}
.menu-bottom {
  @apply text-2xs
}
.menu-bottom a{
  @apply text-gray-400 
}

.menu-bottom .active {
  @apply text-sky-600
}

.scrollmenu {
  
  @apply overflow-x-auto whitespace-nowrap 
}


div.scrollmenu div {
  @apply inline-block text-center px-4 py-3
}
div.scrollmenu div.active {
  @apply border-b-2 border-b-sky-500 text-sky-500 font-semibold
}

.tabmenu {
  
  @apply flex
}


div.tabmenu div {
  @apply inline-block text-center px-4 py-3 flex-1
}
div.tabmenu div.active {
  @apply border-b-2 border-b-sky-500 text-sky-500 font-semibold
}

.referalMenu {
  @apply flex
}


div.referalMenu div {
  @apply inline-block text-center px-4 py-3 w-1/2 border-b-2 border-b-gray-400 text-gray-400 font-semibold cursor-pointer
}
div.referalMenu div.active {
  @apply border-b-2 border-b-orange-500 text-orange-500 font-semibold
}

a:active, a:focus, a:hover {
  text-decoration: none !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}


.custom-timeline {
  margin-left: 20px
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  color: #999;
  font-size: 14px;
  height: 24px;
  justify-content: center;
  left: -2px;
  margin-left: -6px;
  padding: 8px;
  position: absolute;
  top: -8px;
  width: 24px;
  padding: 4px;
}

.custom-timeline .rs-timeline-item-content {
  margin-left: 12px
}



::placeholder {
  color: #ccc !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #ccc !important;
}

.package .rs-drawer-content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rs-drawer-bottom.rs-drawer-sm, .rs-drawer-top.rs-drawer-sm {
  height: 600px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rs-modal-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.chatBox {
  @apply  w-full ring-offset-0 ring-0 focus:ring-offset-0 focus:ring-0  bg-white text-sm appearance-none focus:border-red-300 border-transparent leading-tight focus:outline-none focus:bg-white focus:border-0 outline-none
}


.drawer-rounded .rs-drawer-content {
  @apply  rounded-t-xl
}
.drawer-rounded .rs-drawer-body {
  @apply  rounded-t-xl
}